<script lang="ts" setup>
const props = withDefaults(
	defineProps<{
		icon?: string;
		img?: string;
		variant: "entries" | "coins" | "free-spin";
		iconSize?: number;
		isSvg?: boolean;
		offset?: number;
	}>(),
	{
		iconSize: 16,
		offset: 1
	}
);
const iconSizePx = computed(() => `${props.iconSize}px`);
const imgSizePx = computed(() => `${props.iconSize * 4}px`);
const offset = computed(() => `${props.offset * 8}px`);
const basicSizePx = computed(() => `${props.iconSize}px`);
</script>

<template>
	<div class="prize" :class="[variant]">
		<template v-if="icon">
			<ASvg v-if="isSvg" class="icon" :name="icon" />
			<NuxtIcon v-else :name="icon" class="icon" filled />
		</template>
		<NuxtImg v-if="img" :src="img" :width="imgSizePx" :height="imgSizePx" :alt="variant" format="webp" />
		<slot />
		<slot name="after" />
	</div>
</template>

<style lang="scss">
:root {
	--m-fund-entries-color: var(--cannes);
	--m-fund-coins-color: var(--cannes);
}

.funrize:root {
	--m-fund-entries-color: var(--cixi);
	--m-fund-coins-color: var(--coimbatore);
}
@if mixin-exists(MPrizeFund) {
	@include MPrizeFund;
}
</style>

<style lang="scss" scoped>
.prize {
	display: inline-flex;
	align-items: center;
	gap: v-bind(offset);

	&.entries :slotted(*) {
		text-align: left;

		&:not([class*="text-"]):not([class*="color-"]) {
			color: var(--m-fund-entries-color);
		}
	}

	&.coins :slotted(*) {
		text-align: left;

		&:not([class*="text-"]):not([class*="color-"]) {
			color: var(--m-fund-coins-color);
		}
	}

	img {
		width: v-bind(basicSizePx);
		height: v-bind(basicSizePx);
	}
}
.icon {
	display: flex;
	font-size: v-bind(iconSizePx);
	&:deep(svg) {
		margin: 0;
	}
}
</style>
